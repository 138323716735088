import { SIMetric } from '/public/src/pages/common/monitor/index'

const COLLECT_API_LIST = [
	/* Common */
	'/api/abt/userinfo/query',
	'/api/common/language/get',
	/* 客服 */
	'/api/robot/match/query', // 默认进入机器人会话信息	
	'/api/robot/dialog/get',
	'/api/robot/match/multi/query',
	'/api/robot/channel/check/query',
	'/api/robot/chat/template/query',
	'/api/robot/zdtk/template/query',
	'/api/robot/theme/get',
	'/api/robot/chat/check/query',
	'/api/robot/gwotk/tax/create',
	'/api/robot/gwotk/dulyDeliver/create',
	'/api/robot/cstk/create',
	'/api/robot/gwotk/create',
	'/api/robot/gwotk/quality/create',
	'/api/robot/cschat/token/get',
	'/api/robot/cschat/end/delete',
	'/api/robot/cschat/conversation/create',
	'/api/robot/cschat/message/create',
	'/api/robot/cschat/evaluation/create',
	'/api/robot/cschat/messageReport/create',
	/* 列表 */
	'/api/productList/info/get',
	/* 购物车 */
	'/api/cart/checkcart/get',
	'/api/cart/getNum/get',
	'/api/cart/get',
	'/api/cart/getCart/get',
	'/api/cart/update',
	'/api/cart/delete',
	'/api/cart/checkStock/get',
	'/api/cart/getIntegrityPromotionInfo/get',
	'/api/cart/batchDelete/update',
	'/api/cart/batchWishGoods/update',
	'/api/cart/postModifyCartCheckStatus/update',
	'/api/cart/add_mall/create',
	'/api/cart/batch_add_mall/create',
	'/api/cart/update_attr_mall/update',
	'/api/cart/pageLogo/get',
	'/api/cart/getCartCoupons/get',
	'/api/cart/getGoodsComments/get',
	'/api/cart/communalNoticeCart/get',
	'/api/cart/postGetProductList/get',
	'/api/cart/getCartsWithCouponGift/get',
	'/api/cart/postGetPromotionById/get',
	'/api/cart/postGetAddBuyProductList/get',
	'/api/cart/postGetGiftProductList/get',
	'/api/cart/postFreeMall/get',
	'/api/cart/getCartBriefInfo/get',
	'/api/cart/postSpuProductsCart/get',
	'/api/cart/socialShareGoodsInfo/get',
	'/api/cart/socialShareInfo/get',
	'/api/cart/getProductsCartSharing/get',
	'/api/cart/getCartAllInfo/get',
	'/api/cart/cartAtomicInfo/get',
	'/api/cart/getIncludeTaxInfo/get',
	'/api/cart/fansdiscount/get',
	'/api/cart/batchMemberInfo/get',
	'/api/cart/queryPaymentFrontShowForCartIndex/get',
	'/api/cart/language/get',
	'/api/cart/queryLatestUnPaidOrderInfo/get',
	'/api/cart/getDefaultAddress/get',
	/* 下单 */
	'/api/checkout/get',
	'/api/checkout/mallOrder/create', //下单接口
	'/api/pay/unifiedPay/create', //注意这里礼品卡也会走
	'/result/unifiedCb',
	'/pay/result/success',
	'/pay/result/fail',
	'/api/giftcard/checkout/order/create',
	/* 用户 */
	'/api/user/dashboard/base/get',
	'/api/productList/urlByCode/get',
	/* 客项 */
	'/api/user/addressbook/addressCheckRule/get',
	'/api/user/addressbook/countrySensitiveList/query',
	'/api/user/addressbook/storeConfigInfo/get',
	'/api/user/addressbook/accountAndOrderEmail/get',
	'/api/orders/base/queryAddressInfo/query',
	'/api/user/addressbook/universalCountryList/get',
	'/api/user/addressbook/addressFormConfigByInfo/get',
	'/api/user/addressbook/limitAddr/query',
]

/**
 * 收集客户端RT
 * @param {String} apiPath 
 */
export function checkApiAnalysisCollect(apiPath, { responseTime }) {
	if (COLLECT_API_LIST.some(item => item === apiPath)) {
		SIMetric.metricTime({
			metric_name: 'web_request_duration',
			tags: {
				path: apiPath,
			},
			value: responseTime
		}, {
			random: 0.05
		})
	}
}

